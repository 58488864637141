import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    black: "#181818",
    reallyReallyDarkGrey: "#333",
    white: "#fafafa",
    yellow: "#fce21b",
  },
});

export default theme;
